<template>
  <div class="panel-page_container">
    <div class="panel-page_main">
      <el-table
          :data="tableData"
          style="width: 100%"
          border
          stripe
          v-loading="loading"
          element-loading-background="transparent"
          row-key="id"
        >
          <el-table-column type="index" align="center" width="60" label="序号"></el-table-column>
          <el-table-column
            prop="shopInfo.name"
            label="门店"
            header-align="center"
            align="center"
            min-width="200"
          ></el-table-column>
          <el-table-column
            prop="channelInfo.name"
            label="渠道"
            header-align="center"
            align="center"
            min-width="200"
          ></el-table-column>
          <el-table-column
            prop="employeeInfo.name"
            label="员工"
            header-align="center"
            align="center"
            min-width="200"
          ></el-table-column>
          <el-table-column
            prop="totalNum"
            label="商品数量"
            header-align="center"
            align="center"
            min-width="200"
          ></el-table-column>
          <el-table-column
            prop="totalAmount"
            label="总金额"
            header-align="center"
            align="center"
            min-width="200"
          ></el-table-column>
        </el-table>
        <pagination v-model="paginationData" :total="total" @change="getList"></pagination>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { msgErr, msgSuc } from "@/helpers/message";
import { listSummarySaleData } from "@/apis/unidrink/dashboard";

export default {
  props: {
    type: {
      type: String,
      default: "",
    },
    form:{
      type: Object,
      default: function(){
        return {}
      },
    }
  },
  data() {
    return {
      tableData: [],
      loading: false,
      paginationData: {},
    };
  },
  created() {
  },
  mounted() {},
  methods: {

    getList(form) {
      this.loading = true;
      listSummarySaleData({ ...form, ...this.paginationData })
        .then(({ code, result, message }) => {
          if (code !== "0000") {
            msgErr(message);
            return;
          }
          const { list, total } = result;
          this.tableData = list;
          this.total = total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
